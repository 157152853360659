<template lang="pug">
Layout
  PageHeader(:title=" ( articleId === 'create' ? '建立新稿件' : '編輯稿件: ' ) ", :items="items")
  .row
    //- .col-lg-12.mb-2.title-box
    //- b-button-group.tab-group
    //-   b-button(variant="primary") 編輯稿件
    .col-lg-12.mb-2.title-box
      .col-lg-6
        b-button(variant="secondary" @click="goArticleList") 
          span 返回列表
            small (退出編輯模式)
      .col-6.text-right(v-if="articleId !== 'create'")
        b-button(@click="preView") 
          span(v-if="currentHasPublicVersion && currentArticleStatus === 3") 查看前台
          span(v-else) 前往預覽


    //- 提示用戶有較新的快取版本
    .col-lg-12(v-if="isNewCacheVersion")
      b-card.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row
            .header-title 上次編輯的快取版本
        b-card-body
          .row
            .col-12
              .alert.alert-warning(v-if="isNewCacheVersion")
                .row
                  .col-md-12
                    .text-left
                      | 檢測到較新的快取版本，點擊這裡套用快取版本。
                  .col-md-12.text-right
                    b-button(variant="primary", size="sm", pill, @click="useCacheArticleContent") 套用快取版本
    

    .col-lg-12
      b-card.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row
            .header-title 基本設定
            .from 來源
            b-button.ml-1(variant="primary", size="sm", pill, v-if="currentArticleSource == 1") 後台
            b-button.ml-1(variant="primary", size="sm", pill, v-if="currentArticleSource == 2") RSS
            b-button.ml-1(variant="primary", size="sm", pill, v-if="currentArticleSource == 3") 網友投稿
            b-button.ml-1(variant="primary", size="sm", pill, v-if="currentArticleSource == 4") 網友爆料

        b-card-body
          form.form-horizontal(role="form")
            .row
              .col-lg-4
                .form-group.mb-3
                  label 稿件編號
                  br
                  input.form-control(
                    v-model="currentArticleCode",
                    type="text",
                    disabled,
                    placeholder="編號將於稿件建立後自動產生"
                  )
              .col-lg-4
                .form-group.mb-3
                  label 分類
                  br
                  .row
                    .col
                      multiselect(
                        key="setting-news-main-type"
                        :value="currentMainNewsType",
                        @input="(evt) => onMainTypeChange(evt, 'currentMainNewsType')"
                        :options="$root.newsTypeList",
                        placeholder="選擇主分類",
                        track-by="id",
                        label="name",
                        select-label=""
                      )
                    .col-6(v-if="currentMainNewsType")
                      multiselect(
                        key="setting-news-sub-type"
                        v-model="currentSubNewsType",
                        :options="currentMainNewsType.sub_types",
                        placeholder="選擇次分類",
                        track-by="id",
                        label="name",
                        select-label=""
                      )
              .col-lg-4
                .form-group.mb-3
                  label 旺幣獎勵
                  br
                  input.form-control(
                    v-model="currentWanCoin"
                    type="number",
                    placeholder="請輸入旺幣獎勵"
                  )
            .row
              .col-lg-4
                .form-group.mb-3
                  label.form-label-row
                    span 上稿人員
                  select.form-control(
                    v-model="currentEditerName",
                    type="text",
                    placeholder="請輸入上稿人員",
                  )
                    option(value="") 請選擇上稿人員
                    option(v-if="needShowEditorName(currentEditerName, ownEditorName)" :value="currentEditerName") {{ currentEditerName }}
                    option(:value="(($root.user.editor_name) ? $root.user.editor_name : $root.user.name)") {{ (($root.user.editor_name) ? $root.user.editor_name : $root.user.name) }}
                    option(value="即時中心") 即時中心
                    option(value="要聞中心") 要聞中心
                    option(value="娛樂中心") 娛樂中心
                    option(value="大國中心") 大國中心
                    option(value="健康中心") 健康中心

              .col-lg-4
                .form-group.mb-3
                  label 上架日期
                  br
                  date-picker(
                    type="datetime"
                    ref="postDate"
                    :is-24="true"
                    v-model="currentPostDate",
                    format="YYYY-MM-DD HH:mm"
                    placeholder="請選擇上架日期"
                  )
              .col-lg-4
                .form-group.mb-3
                  label.form-label-row
                    span 下架日期
                    b-form-checkbox(
                      v-model="isArticleNoOffline",
                      name="checkbox",
                      value="accepted",
                      unchecked-value="not_accepted"
                    ) 不下架
                  //- br
                  date-picker(
                    type="datetime"
                    ref="downDate"
                    :is-24="true"
                    v-model="currentDownDate",
                    format="YYYY-MM-DD HH:mm"
                    placeholder="請選擇下架日期",
                    :disabled="isArticleNoOffline === 'accepted'"
                  )
            .row
              .col-lg-3
                .form-group.mb-3
                  label 新聞類型
                  br
                  .row
                    b-button.btn-rounded.ml-1(
                      :variant="currentNewsType === 'text' ? 'primary' : 'outline-primary'",
                      @click="setNewsMarkType('currentNewsType', 'text')"
                    ) 文字新聞
                    b-button.btn-rounded.ml-1(
                      :variant="currentNewsType === 'video' ? 'primary' : 'outline-primary'",
                      @click="setNewsMarkType('currentNewsType', 'video')"
                    ) 影音新聞
              .col-lg-3
                .form-group.mb-3
                  label 上架平台
                  br
                  .row
                    b-button.btn-rounded.ml-1(
                      :variant="currentReleasePlatform === 0 ? 'primary' : 'outline-primary'",
                      @click="setNewsMarkType('currentReleasePlatform', 0)"
                    ) 全部
                    b-button.btn-rounded.ml-1(
                      :variant="currentReleasePlatform === 1 ? 'primary' : 'outline-primary'",
                      @click="setNewsMarkType('currentReleasePlatform', 1)"
                    ) 網頁
                    b-button.btn-rounded.ml-1(
                      :variant="currentReleasePlatform === 2 ? 'primary' : 'outline-primary'",
                      @click="setNewsMarkType('currentReleasePlatform', 2)"
                    ) APP
              //- 20230504 需求，僅限具有推播權限者可在編輯頁異動此選項
              .col-lg-3(v-if="$root.user.permission.indexOf('MENU_BROADCAST')")
                .form-group.mb-3
                  label 上架時發送推播(所有裝置)
                  br
                  .row
                    b-button.btn-rounded.ml-1(
                      :variant="currentNeedBroadcast === 0 ? 'primary' : 'outline-primary'",
                      @click="setNewsMarkType('currentNeedBroadcast', 0)"
                    ) 不需發送
                    b-button.btn-rounded.ml-1(
                      :variant="currentNeedBroadcast === 1 ? 'primary' : 'outline-primary'",
                      @click="setNewsMarkType('currentNeedBroadcast', 1)"
                    ) 需要發送
              .col-lg-3
                .form-group.mb-3
                  label.form-label-row
                    span 稿件標示 (可複選)
                    b-form-checkbox(
                      v-model="currentIsNoMark",
                      name="checkbox",
                      value="accepted",
                      unchecked-value="not_accepted"
                    ) 無
                  //- br
                  .row
                    b-button.btn-rounded.ml-1(
                      :variant="currentNewsMarks.includes('adults') ? 'primary' : 'outline-primary'",
                      @click="setNewsMarkType('currentNewsMarks', 'adults')", 
                      :disabled="currentIsNoMark == 'accepted'"
                    ) 成人
                    b-button.btn-rounded.ml-1(
                      :variant="currentNewsMarks.includes('authorize') ? 'primary' : 'outline-primary'",
                      @click="setNewsMarkType('currentNewsMarks', 'authorize')", 
                      :disabled="currentIsNoMark == 'accepted'"
                    ) 對外授權
                    b-button.btn-rounded.ml-1(
                      :variant="currentNewsMarks.includes('twLineToday') ? 'primary' : 'outline-primary'",
                      @click="setNewsMarkType('currentNewsMarks', 'twLineToday')", 
                      :disabled="currentIsNoMark == 'accepted'"
                    ) 台灣LineToday
                    b-button.btn-rounded.ml-1(
                      :variant="currentNewsMarks.includes('business') ? 'primary' : 'outline-primary'",
                      @click="setNewsMarkType('currentNewsMarks', 'business')", 
                      :disabled="currentIsNoMark == 'accepted'"
                    ) 業配專用
                    b-button.btn-rounded.ml-1(
                      :variant="currentNewsMarks.includes('businessDelay') ? 'primary' : 'outline-primary'",
                      @click="setNewsMarkType('currentNewsMarks', 'businessDelay')", 
                      :disabled="currentIsNoMark == 'accepted'"
                    ) 延遲授權
                    b-button.btn-rounded.ml-1(
                      :variant="currentNewsMarks.includes('aiModelArticle') ? 'primary' : 'outline-primary'",
                      @click="setNewsMarkType('currentNewsMarks', 'aiModelArticle')", 
                      :disabled="currentIsNoMark == 'accepted'"
                    ) AI生成文章
            hr
            .row
              .col-12 
                label 次級分類設定
            .row
              .col-lg-4(v-for="(typeItem, index) in secondNewsTypeList")
                .row
                  .col-auto.pr-0.pt-1 {{ index + 1 }}.
                  .col.pr-0
                    .form-group
                      multiselect(
                        :key="'setting-news-other-main-type-' + (index+1)"
                        :value="typeItem.main",
                        @input="(evt) => onMainTypeChange(evt, 'other-main-type', index)"
                        :options="$root.newsTypeList",
                        :allow-empty="false"
                        deselect-label="Can't remove this value",
                        placeholder="選擇主分類",
                        track-by="id",
                        label="name",
                        select-label=""
                      )
                  .col-5.pr-0(v-if="typeItem.main.id")
                    .form-group
                      multiselect(
                        :key="'setting-news-other-sub-type-' + (index+1)"
                        v-model="typeItem.sub",
                        :options="typeItem.main.sub_types",
                        :allow-empty="false"
                        deselect-label="Can't remove this value",
                        placeholder="選擇次分類",
                        track-by="id",
                        label="name",
                        select-label=""
                      )
                  .col-1.pl-1(v-if="index !== 0")
                    .del-btn(@click="deleteSecond(index)")
                      b-button.btn(
                        href="javascript:void(0);",
                        size="lg",
                        variant="primary"
                      )
                        i.mdi.mdi-close
            .row
              .col-lg-12
                b-button.add-btn.width-lg.mb-2(
                  variant="outline-primary",
                  @click="addNewSecond()"
                ) ＋新增一組分類

      //-
      b-card.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row
            .header-title 內容設定

        b-card-body
          form.form-horizontal(role="form")
            .row
              .col-lg-6
                .form-group.mb-3
                  label 新聞封面
                  br
                  SelectImage(
                    key="NewsImage"
                    itemKey="NewsImage"
                    type="image"
                    @upDateImage="setNewsImage"
                    :value="currentNewsImage"
                  )
                  .img-view
                    i.fe-image(v-if="!currentNewsImage")
                    img(v-if="currentNewsImage" :src='currentNewsImage.url' rounded )
              .col-lg-6
                .form-group.mb-3
                  label 社群新聞封面
                  br
                  SelectImage(
                    key="NewsShareImage"
                    type="image"
                    itemKey="NewsShareImage"
                    @upDateImage="setNewsShareImage"
                    :value="currentNewsShareImage"
                  )
                  .img-view
                    i.fe-image(v-if="!currentNewsShareImage")
                    img(v-if="currentNewsShareImage" :src='currentNewsShareImage.url' rounded )
            .row
              .col-lg-6
                .form-group.mb-3
                  .row.lock-position
                    .col-6.text-left.pt-1
                      label 標題
                        small.pl-2 目前字數: {{ $root.common.getStringLenCount(currentNewsTitle) }}
                    .col-6.text-right.pb-2
                      button.ml-3.btn.btn-white.btn-xs(type="button", @click="getNewsTitleByAi()")
                        span AI 產製建議標題
                      b-spinner.process-ing-icon(v-if="processTitleStatus" class="m-2" variant="primary" role="status")
                  input.form-control(
                    v-model="currentNewsTitle",
                    type="text",
                    placeholder="請輸入標題，建議25個字"
                    @change="checkNewsTitleChange"
                  )

              .col-lg-6
                .form-group.mb-3
                  label 社群標題
                    small.pl-2 目前字數: {{ $root.common.getStringLenCount(currentShareTitle) }}
                  br
                  input.form-control(
                    v-model="currentShareTitle",
                    type="text",
                    placeholder="請輸入標題，建議25個字"
                  )
            .row
              .col-lg-6
                .form-group.mb-3
                  label 摘要
                    small.pl-2 目前字數: {{ $root.common.getStringLenCount(currentNewsDes) }}
                  br
                  textarea.form-control(
                    v-model="currentNewsDes",
                    rows="3",
                    type="text",
                    placeholder="請輸入摘要"
                    @change="checkNewsDesChange"
                  )
              .col-lg-6
                .form-group.mb-3
                  label 社群摘要
                    small.pl-2 目前字數: {{ $root.common.getStringLenCount(currentShareDes) }}
                  br
                  textarea.form-control(
                    v-model="currentShareDes",
                    rows="3",
                    type="text",
                    placeholder="請輸入摘要"
                  )
              .col-lg-6
                .form-group.mb-3
                  .row.lock-position
                    .col-6.text-left.pt-1
                      label TAG
                    .col-6.text-right.pb-2
                      button.ml-3.btn.btn-white.btn-xs(type="button", @click="getNewsKeywordByAi()")
                        span AI 產製建議標籤
                      b-spinner.process-ing-icon(v-if="processKeyWordStatus" class="m-2" variant="primary" role="status")
                  multiselect(
                    key="setting-news-tag"
                    v-model="currentNewsTags",
                    :options="NewsTags",
                    placeholder="請輸入 TAG 名稱",
                    label="name",
                    track-by="id",
                    select-label=""
                    :multiple="true",
                    :taggable="true",
                    @tag="addTag"
                    @search-change="searchTagList"
                  )
            .row(v-if="currentNewsType !== 'text'")
              .col-lg-6
                .form-group.mb-3
                  label 影音新聞
                  br
                  SelectImage(
                    key="NewsVideo"
                    type="video"
                    itemKey="NewsVideo"
                    @upDateImage="setNewsVideo"
                    :value="currentNewsVideo"
                    :multiple="false",
                  )
                  .img-view
                    .video-view.d-flex.align-items-center.justify-content-center(
                      v-if="currentNewsVideo!== null && currentNewsVideo.url && show_Video" 
                      style="width: 100%; height:100%; overflow:hidden;"
                    )
                      video.article-video(ref="NewsVideo",:autoplay="true",controls)
                        source(:src="getMp4VideoByM3u8File(currentNewsVideo.url)")
            hr 
            .row
              .col-lg-6
                h4 正文編輯
                  small.pl-2 目前字數: {{ $root.common.getStringLenCount(articleContent) }}
              .col-lg-6.text-right
                .auto-save-content
                  b-tooltip(target="tooltip-top") Tooltip on top
                  span(v-if="autoSaveSecondLock") 貼心提醒: 稿件備份中...
                  span(v-else) 
                    span 
                      b 貼心提醒: 
                    span 下次
                    button.function-action(id="tooltip-button",variant="light",type="button",@click="showAutoSaveInfo") 內容備份
                    span : {{ autoSaveSeconds }}s
                .position-fix.text-to-voice-checkbox
                  label.form-label-row.justify-content-between
                    b-form-checkbox(
                      v-model="isOpenArticleVoice",
                      name="checkbox",
                      value="accepted",
                      unchecked-value="not_accepted"
                    ) 啟用文字轉語音功能
            .row
              .col-lg-12
                #ckeditor-editor.form-group.mb-3
                  //- 稿件本文編輯區域
                  .article-editor-container(v-if="articleReadyStatus")
                    Ckeditor(:content="articleContent", rows="20", @setcontent="setNewsContent")
                  .article-editor-container(v-else)
                    .article-editor-loading
                      b-spinner(type="grow" variant="primary")
            .row 
              .col-lg-6
                .form-group.mb-3
                  label
                    span 相關報導
                  .row 
                    .col-4.pr-0
                      multiselect(
                        key="setting-relation-option"
                        v-model="currentRelationType", 
                        :options="OtherArticleTypes", 
                        placeholder="請選擇設定方式", 
                        label="name",
                        track-by="id",
                        select-label="" 
                      )
                    .col-8
                      input.form-control(v-if="!currentRelationType", disabled)
                      multiselect(
                        v-if="currentRelationType && currentRelationType.id === 'tag'",
                        key="setting-relation-tags"
                        placeholder="請輸入 TAG 名稱",
                        v-model="currentRelationTags",
                        :options="RelationTags",
                        label="name",
                        track-by="id",
                        select-label=""
                        :multiple="false",
                        @search-change="searchRelationTagList"
                      )
                      multiselect(
                        v-if="currentRelationType && currentRelationType.id === 'code'",
                        key="setting-relation-news"
                        v-model="currentRelationNews",
                        :options="RelationNews",
                        placeholder="請輸入稿件編號或標題",
                        label="name",
                        track-by="code",
                        select-label=""
                        :multiple="true"
                        @search-change="searchRelationNewsList"                        
                      )
              .col-lg-6
                .form-group.mb-3
                  label
                    span 延伸閱讀 (Yahoo會抓延伸閱讀請至少加入3則新聞，最多加入8則新聞)
                  .row 
                    .col-4.pr-0
                      multiselect(
                        key="setting-extension-option"
                        v-model="currentExtendingType", 
                        :options="OtherArticleTypes", 
                        placeholder="請選擇設定方式", 
                        label="name",
                        track-by="id",
                        select-label="" 
                      )
                    .col-8
                      input.form-control(v-if="!currentExtendingType", disabled)
                      multiselect(
                        v-if="currentExtendingType && currentExtendingType.id === 'tag'",
                        key="setting-extension-tags"
                        placeholder="請輸入 TAG 名稱",
                        v-model="currentExtendingTags",
                        :options="ExtendingTags",
                        label="name",
                        track-by="id",
                        select-label=""
                        :multiple="false",
                        @search-change="searchExtendingTagList"
                      )
                      multiselect(
                        v-if="currentExtendingType && currentExtendingType.id === 'code'",
                        key="setting-extension-news"
                        v-model="currentExtendingNews",
                        :options="ExtendingNews",
                        placeholder="請輸入稿件編號或標題",
                        label="name",
                        track-by="code",
                        select-label=""
                        :multiple="true"
                        @search-change="searchExtendingNewsList"                        
                      )
            hr
            .row
              .col-12 
                label 文字導購 (選填)
            .row(v-for="(guide, index) in currentGuideList")
              .col-lg-2
                .form-group.mb-3
                  select.form-control(v-model="guide.type")
                    option 設定方式
                    option(value="1") 自定義內容
                    option(value="2") 從文章帶入
              .col-lg-5(v-if="guide.type == 1")
                .form-group.mb-3
                  input.form-control(
                    v-model="guide.text",
                    type="text",
                    placeholder="請輸入導購文字,限20個字"
                  )
              .col-lg-5(v-if="guide.type == 1")
                .form-group.mb-3
                  input.form-control(
                    v-model="guide.uri",
                    type="text",
                    placeholder="請輸入商品連結"
                  )
              .col-lg-4(v-if="guide.type == 2")
                multiselect(
                  :key="'setting-guide-line-' + index"
                  v-model="guide.target",
                  :options="searchArticles",
                  placeholder="請輸入稿件編號或標題",
                  label="name",
                  track-by="code",
                  select-label=""
                  :multiple="false"
                  @search-change="searchArticleList"
                )
              .col-1(v-if="index !== 0")
                .del-btn(@click="deleteGuide(index)")
                  b-button.btn(
                    href="javascript:void(0);",
                    size="lg",
                    variant="primary"
                  )
                    i.mdi.mdi-close
            .row
              .col-lg-10
                b-button.add-btn.width-lg.mb-2(
                  variant="outline-primary",
                  @click="addNewGuide()"
                ) ＋新增一則 （最多7則）

            hr
            .row
              .col-lg-12
                .gray-box(v-if="articleId === 'create'")
                  b-button.width-sm.mr-2(
                    v-if="$root.common.confirmUserAccess('ARTICLE_CREATE_NEW')" 
                    variant="primary"
                    @click="createArticle"
                  ) 新增
                .gray-box(v-else)
                  b-button.width-sm.mr-2(
                    variant="dark"
                    @click="preView"
                  ) 
                    span(v-if="currentHasPublicVersion && currentArticleStatus === 3") 查看前台
                    span(v-else) 前往預覽                  
                  b-button.width-sm.mr-2(
                    v-if="$root.common.confirmUserAccess('ARTICLE_EDIT_CONTENT')" 
                    variant="primary"
                    @click="putArticleDetail"
                  ) 儲存
                  b-button.width-sm.mr-2(
                    v-if="currentArticleStatus == 0 && $root.common.confirmUserAccess('ARTICLE_EDIT_CONTENT')"
                    variant="primary"
                    @click="submitArticle"
                  ) 送審
                  b-button.width-sm.mr-2(
                    v-if="currentArticleStatus == 1 && $root.common.confirmUserAccess('ARTICLE_PUBLISH_CONTENT')"
                    @click="returnArticle"
                    variant="danger"
                  ) 退回
                  b-button.width-sm.mr-2(
                    v-if="currentArticleStatus == 1 && $root.common.confirmUserAccess('ARTICLE_PUBLISH_CONTENT')"
                    variant="primary"
                    @click="setArticleOnline"
                  ) 上架
                  b-button.width-sm(
                    v-if="currentHasPublicVersion && $root.common.confirmUserAccess('ARTICLE_REVOKE_CONTENT')"
                    @click="setArticleOffline"
                    variant="danger"
                  ) 下架

      b-card.mb-1(no-body)
        b-card-header(header-tag="header", role="tab")
          .header-row
            .header-title 內部公告

        b-card-body
          .row 
            .col-12
              .announce-item
                .announce-title {{ ArticleBoard.title }}
                .announce-text(v-html="ArticleBoard.content")
        
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/Page-header";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import Select2 from 'v-select2-component';
import SelectImage from "@/components/select-image";
import Ckeditor from "@/components/Ckeditor.vue";
import md5 from 'js-md5'
/**
 * Starter component
 */
export default {
  name: "ArticleEditor",
  data () {
    return {
      // 麵包屑
      items: [{
        text: "上稿管理",
        href: "/",
      },
      {
        text: "新聞/文章編輯",
        href: '/articles',
      },
      {
        text: "新聞稿件編輯",
        active: true,
      }
      ],
      // 初始稿件資料
      initialArticle: {},
      // 稿件資料
      article: {},
      // 自動存檔案
      autoSaveSeconds: 60,
      autoSaveTempData: {},
      autoSaveSecondStatus: false,
      autoSaveSecondLock: false,
      processKeyWordStatus: false,
      processTitleStatus: false,
      articleReadyStatus: false,
      suggestionTitles: [
        "AAAAAAAA",
        "AAAAAAAA",
        "AAAAAAAA",
      ],
      // 新聞項目ID
      tempId: "",
      articleId: "",
      // 稿件來源
      currentArticleSource: 1,
      // 文章正文內容
      articleContent: "",
      // 稿件類型
      currentNewsType: "text",
      // 稿件主要類別
      currentMainNewsType: null,
      currentSubNewsType: null,
      // 稿件獎勵旺幣
      currentWanCoin: 0,
      // 稿件編輯者筆名
      currentEditer: null,
      currentEditerName: "",
      ownEditorName: "",
      // 稿件發布平台
      currentReleasePlatform: 0,
      // 稿件需要推播
      currentNeedBroadcast: 0,
      // 是否具有稿件標示
      currentIsNoMark: 'accepted',
      // 稿件標記項目
      currentNewsMarks: [],
      // 稿件次級分類設定
      secondNewsTypeList: [],
      // 稿件編號
      currentArticleCode: "",
      // 稿件上稿日期
      currentPostDate: "",
      // 稿件下架日期
      currentDownDate: "",
      // 稿件基礎大圖
      currentNewsImage: null,
      // 稿件分享大圖
      currentNewsShareImage: null,
      // 稿件站內標題
      currentNewsTitle: "",
      // 稿件社群標題
      currentShareTitle: "",
      // 稿件站內摘要
      currentNewsDes: "",
      // 稿件社群摘要
      currentShareDes: "",
      // 稿件影音檔案
      currentNewsVideo: null,
      show_Video: {},
      // 稿件設置的標籤
      currentNewsTags: null,
      // 稿件相關報導的標籤們
      currentRelationTags: null,
      // 稿件相關報導的新聞們
      currentRelationNews: null,
      // 稿件延伸新聞的標籤們
      currentExtendingTags: null,
      // 稿件延伸新聞的新聞們
      currentExtendingNews: null,
      // 稿件標籤清單
      NewsTags: [],
      // 相關報導標籤清單
      RelationTags: [],
      // 相關報導稿件清單
      RelationNews: [],
      // 延伸閱讀標籤清單
      ExtendingTags: [],
      // 延伸閱讀稿件清單
      ExtendingNews: [],
      // 上稿人員是否使用筆名
      isEditorUseNiName: false,
      // 稿件是否不需下架
      isArticleNoOffline: "accepted",
      // 稿件是否啟用文字轉語音
      isOpenArticleVoice: "accepted",
      // 相關報導可使用的類型
      OtherArticleTypes: [
        { id: 'tag', name: 'tag' },
        { id: 'code', name: '稿號' }
      ],
      // 稿件相關報導使用類型
      currentRelationType: null,
      // 稿件延伸閱讀使用類型
      currentExtendingType: null,
      // 文章目前是否有公開版本
      currentHasPublicVersion: false,
      // 文章目前所在稿件夾
      currentArticleStatus: 0,
      // 文字導購清單
      currentGuideList: [{
        type: '1',
        text: '',
        uri: '',
        target: {},
      }],
      searchArticles: [],
      // 內部公告內容
      ArticleBoard: {
        title: "",
        content: ""
      },
      interval: false,
      // 處理作業狀態
      processStatus: false,
      // 發現較新的快取版本
      isNewCacheVersion: false,
      // 快取版本的新聞內容
      cacheArticleContent: {},
    }
  },
  computed: {

  },
  mounted () {
    window.onbeforeunload = () => window.confirm()
  },
  beforeRouteLeave (to, from, next) {
    const vm = this
    if (this.articleId === 'create') {
      this.$root.common.confirmAction(
        "確定要離開嗎?",
        "請記得新增您的稿件內容，否則將會遺失",
        "離開", "繼續建立稿件",
        function () {
          vm.$root.apis.resetArticleCanEditStatus(vm.articleId)
          return next()
        },
        function () {
          return next(false)
        }
      )
    } else if (this.checkIsEdit()) {
      this.$root.common.confirmAction(
        "確定要離開嗎?",
        "請記得儲存您的編輯內容，否則將會遺失",
        "不儲存離開", "繼續編輯",
        function () {
          vm.$root.apis.resetArticleCanEditStatus(vm.articleId)
          return next()
        },
        function () {
          return next(false)
        }
      )
    } else {
      vm.$root.apis.resetArticleCanEditStatus(vm.articleId)
      return next()
    }
  },
  beforeDestroy () {
    window.onbeforeunload = null
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    DatePicker,
    Select2,
    SelectImage,
    Ckeditor
  },
  watch: {
    articleId () {
      if (this.articleId === 'create') {
        return
      }

      if (this.tempId !== this.articleId) {
        this.getNewsDetail()
      }
    },
    autoSaveSeconds (_seconds) {
      if (_seconds <= 3) {
        console.log('--> 自動存檔倒數計時: ', _seconds)
      }

      if (_seconds === 0) {
        this.setArticleCache()
      }
    },
    // 稿件標示是否勾選無
    'currentIsNoMark' (_val) {
      if (_val === 'accepted') {
        this.currentNewsMarks = []
      }
    },
    'currentNewsVideo' (_val) {
      if (_val) {
        this.show_Video = false
        setTimeout(() => {
          this.show_Video = true
        }, 1000);
      }
    },
    // 當用戶勾選了需要推播通知時
    currentNeedBroadcast (_currentNeedBroadcast) {
      if (_currentNeedBroadcast) {
        this.$root.common.showErrorDialog("請注意:\r\n\r\n文章上架時自動對所有裝置進行推送")
      }
    },
    '$root.system_ready' (_status) {
      this.ownEditorName = ((this.$root.user.editor_name) ? this.$root.user.editor_name : this.$root.user.name)
    }
  },
  created () {
    // 獲得操作文章ID
    this.tempId = this.$route.params.NewsHashId
    this.articleId = this.$route.params.NewsHashId
    // 獲得單一新聞文章
    this.getNewsDetail()
    // 獲得內部公告
    this.getArticleBoardDetail()
    // 延遲處理作業
    setTimeout(() => {
      // 處理稿件次級分類
      this.setArticle()
    }, 1500)
    setTimeout(() => {
      // 處理稿件編輯判斷
      this.editArticle(this.articleId)
    }, 3000);
    setTimeout(() => {
      this.ownEditorName = ((this.$root.user.editor_name) ? this.$root.user.editor_name : this.$root.user.name)
    }, 1000);
    // 處理自動稿件存檔作業
    clearInterval(this.interval)
    setTimeout(() => {
      if (!this.interval) {
        console.log('--> 初始化處理自動稿件存檔作業...')
        this.interval = setInterval(this.processAutoSaveSeconds, 1000)
      }
    }, 5000);
  },
  methods: {
    // 請求預覽文章
    preView () {
      if (this.articleId === 'create') {
        return this.$root.common.showErrorDialog('尚未建立的文章無法預覽哦！')
      }

      if (this.article.review_at) {
        window.open(this.$root.site_uri + '/news/items/' + this.articleId)
      }

      else {
        window.open(this.$root.site_uri + '/news/items/' + this.articleId + "?mode=preview")
      }
    },
    // 處理稿件次級分類
    async setArticle () {
      if (this.articleId !== 'create')
        return

      // 處理稿件預設的發布日期
      this.currentPostDate = new Date()

      let memberName = this.$root.user.name
      let editorName = this.$root.user.editor_name

      // 處理預設的編輯者資訊
      let user = this.$root.user
      this.currentEditer = {
        id: user.id,
        name: (editorName) ? editorName : memberName
      }
      this.currentEditerName = (editorName) ? editorName : memberName

      // 預設帶入第一組次級分類 -> 即時要聞
      this.$root.common.searchNewsType("即時", "要聞", this.setArticleSuccess)
    },
    // 子分類處理作業完成
    setArticleSuccess (_searchNewsType) {
      this.secondNewsTypeList.push(_searchNewsType)
    },
    // 獲得單一新聞文章
    getNewsDetail () {
      if (this.articleId == 'create') {
        // 獲得稿件快取紀錄
        this.getArticleCache()
        return
      }

      this.$root.apis.getNewsDetail(this.articleId, this.getNewsDetailSuccess)
    },
    async getNewsDetailSuccess (_response) {
      let article = _response.body.data
      // 處理搞件內容設置
      await this.setArticleData(article)
      // 獲得稿件快取紀錄
      await this.getArticleCache(article.content_version)
    },
    getNewsTitleByAi () {
      // 如果字數低於 50 字
      if (this.articleContent.length <= 50) {
        this.$root.common.showErrorDialog("文章字數較少，不建議採用 AI 產製")
        return
      }

      let vm = this
      this.processTitleStatus = true
      this.$root.apis.getNewsTitleByAi(this.articleContent, function (_response) {
        let response = _response.body.data
        let titles = Array.isArray(response.titles) ? response.titles : []
        if (Array.isArray(titles) && titles.length > 0) {

          let htmlText = '<div class="suggestion-title-list text-left">'
          titles.forEach(function (_item, _index) {
            if (_item.title) {
              htmlText += '<div class="mb-1">' + (_index + 1) + '. ' + _item.title + '</div>'
            }
          })
          htmlText += '</div>'

          vm.$root.common.showSuccessDialog("AI 建議的標題", htmlText, "關閉")
        }

        else {
          vm.$root.common.showErrorDialog("AI 沒有回覆建議標題, 請稍後再試！")
        }

        vm.processTitleStatus = false
      }, function () {
        vm.processTitleStatus = false
      })
    },
    getNewsKeywordByAi () {
      // 如果字數低於 50 字
      if (this.articleContent.length <= 50) {
        this.$root.common.showErrorDialog("文章字數較少，不建議採用 AI 產製")
        return
      }

      let vm = this
      this.processKeyWordStatus = true
      this.$root.apis.getNewsKeywordByAi(this.articleContent, function (_response) {
        let response = _response.body.data
        let keywords = Array.isArray(response.keywords) ? response.keywords : []
        if (Array.isArray(keywords) && keywords.length > 0) {
          keywords.forEach(function (_keyword) {
            if (_keyword.word) {
              vm.currentNewsTags.push({ id: "", name: _keyword.word })
            }
          })
        }

        else {
          vm.$root.common.showErrorDialog("AI 沒有回覆建議關鍵字, 請稍後再試！")
        }

        vm.processKeyWordStatus = false
      })
    },
    setArticleData (article) {
      let vm = this
      // 處理文章資訊
      this.currentNewsType = article.class
      this.currentArticleCode = article.code
      this.articleContent = (article.content) ? article.content : ""
      this.currentNewsImage = { id: "", url: article.image }
      this.currentNewsShareImage = { id: "", url: article.og_image }
      this.currentNewsTitle = article.title
      this.currentShareTitle = article.og_title
      this.currentNewsDes = article.description
      this.currentShareDes = article.og_description
      this.currentGuideList = article.directs
      this.currentEditerName = (article.editer_name) ? article.editer_name : article.editer.name
      this.isEditorUseNiName = (article.editer.use_niname) ? 'accepted' : 'not_accepted'
      this.currentNewsMarks = article.marks
      this.currentIsNoMark = (article.marks.length) ? 'not_accepted' : 'accepted'
      this.currentNewsTags = article.tagDatas
      this.currentWanCoin = article.wancoin
      this.currentPostDate = this.$root.common.getNowTime('Object', article.post_date + " " + article.post_time)
      this.currentDownDate = this.$root.common.getNowTime('Object', article.unpost_date + " " + article.unpost_time)
      this.isArticleNoOffline = (article.unpost_date) ? 'not_accepted' : 'accepted'
      this.currentArticleSource = article.source
      this.currentHasPublicVersion = article.is_online
      this.currentArticleStatus = article.status
      this.currentNewsVideo = (article.video_id) ? { url: article.video_id, file_name: article.video_name } : false
      // 處理相關報導的內容
      if (article.relation_news.use_type) {
        if (article.relation_news.use_type == "tag") {
          this.currentRelationType = { id: "tag", name: "tag" }
        }
        if (article.relation_news.use_type == "code") {
          this.currentRelationType = { id: "code", name: "稿號" }
        }
        if (this.currentRelationType.id === "tag") {
          this.currentRelationTags = article.relation_news.data[0]
        }
        if (this.currentRelationType.id === "code") {
          vm.currentRelationNews = []
          article.relation_news.data.forEach(function (_article) {
            vm.currentRelationNews.push({ id: _article.id, code: _article.id, name: '(' + _article.id + ')' + _article.name })
          })
        }
      }

      // 處理延伸新聞的內容
      if (article.extending_news.use_type) {
        if (article.extending_news.use_type == "tag") {
          this.currentExtendingType = { id: "tag", name: "tag" }
        }
        if (article.extending_news.use_type == "code") {
          this.currentExtendingType = { id: "code", name: "稿號" }
        }
        if (this.currentExtendingType.id === "tag") {
          this.currentExtendingTags = article.extending_news.data[0]
        }
        if (this.currentExtendingType.id === "code") {
          vm.currentExtendingNews = []
          article.extending_news.data.forEach(function (_article) {
            vm.currentExtendingNews.push({ id: _article.id, code: _article.id, name: '(' + _article.id + ')' + _article.name })
          })
        }
      }

      // 延遲一下子處理文章分類
      setTimeout(() => {
        // 處理主要分類 
        let targetType = this.processArticleType(article.main_type)
        this.currentMainNewsType = targetType.main
        this.currentSubNewsType = targetType.sub
        // 處理次要分類
        if (article.sub_types) {
          this.secondNewsTypeList = []
          article.sub_types.forEach(function (_targetType) {
            targetType = vm.processArticleType(_targetType)
            vm.secondNewsTypeList.push(targetType)
          })
        }
        this.initialArticle = this.processArticleData()
      }, 500)
    },
    // 把後端提供的分類進行符合前端呈現的資料格式
    processArticleType (_SourceType) {
      let targetNewsType = {}
      let mainType = _SourceType.father
      this.$root.newsTypeList.forEach(function (_type) {
        if (_type.id === mainType.id) {
          targetNewsType.main = _type
        }
      })
      if (targetNewsType.main) {
        targetNewsType.sub = {
          id: _SourceType.id,
          name: _SourceType.name
        }
      }
      return targetNewsType
    },
    // 設置新聞標記類型
    setNewsMarkType (_tabGroup, _status) {
      // 設置新聞類型
      if (_tabGroup === 'currentNewsType') {
        this.currentNewsType = _status
      }
      if (_tabGroup === 'currentReleasePlatform') {
        this.currentReleasePlatform = _status
      }
      if (_tabGroup === 'currentNeedBroadcast') {
        this.currentNeedBroadcast = _status
      }
      if (_tabGroup === 'currentNewsMarks') {
        if (this.currentNewsMarks.includes(_status)) {
          const index = this.currentNewsMarks.indexOf(_status)
          this.currentNewsMarks.splice(index, 1)
        } else {
          this.currentNewsMarks.push(_status)
        }
      }
    },
    // 設定新聞大圖項目
    setNewsImage (_image) {
      this.currentNewsImage = _image
      if (!this.currentNewsShareImage) {
        this.setNewsShareImage(_image)
      }
    },
    // 設定新聞影音項目
    setNewsVideo (_video) {
      this.currentNewsVideo = _video
    },
    // 設定新聞分享大圖
    setNewsShareImage (_image) {
      this.currentNewsShareImage = _image
    },
    // 搜尋符合的標籤
    searchTagList (_text) {
      this.processSearchTagList(_text, 'article')
    },
    // 搜尋符合的標籤
    searchRelationTagList (_text) {
      this.processSearchTagList(_text, 'relation')
    },
    // 搜尋符合的標籤
    searchExtendingTagList (_text) {
      this.processSearchTagList(_text, 'extending')
    },
    // 處理標籤清單更新作業
    processSearchTagList (_text, _type) {
      let vm = this
      console.log("=> 標籤搜尋:", _text)
      this.$root.apis.getFastTagList({
        name: _text,
        per_page: 100
      }, function (_response) {
        switch (_type) {
          case 'article':
            vm.NewsTags = _response.body.data.items
            break;
          case 'relation':
            vm.RelationTags = _response.body.data.items
            break;
          case 'extending':
            vm.ExtendingTags = _response.body.data.items
            break;
        }
      })
    },
    // 請求增加一個新的標籤
    addTag (newTag) {
      // 預防因資料問題導致無法使用
      this.currentNewsTags = (this.currentNewsTags === null) ? [] : this.currentNewsTags
      // 建立一個空的標籤
      let tag = { id: "", name: newTag, }
      this.NewsTags.push(tag)
      this.currentNewsTags.push(tag)
    },
    // 用戶變更新聞標題時
    checkNewsTitleChange () {
      if (!this.currentShareTitle)
        this.currentShareTitle = this.currentNewsTitle
    },
    // 用戶變更新聞摘要時
    checkNewsDesChange () {
      if (!this.currentShareDes)
        this.currentShareDes = this.currentNewsDes
    },
    // 針對 m3u8 網址進行特殊處理
    getMp4VideoByM3u8File (_uri) {
      _uri = _uri.replace('.m3u8', '')
      _uri = _uri.replace('/hls', '')
      return _uri
    },
    // 搜選相關報導的新聞列表
    searchRelationNewsList (_keyword) {
      let vm = this
      this.processSearchNewsList(_keyword, function (items) {
        vm.RelationNews = []
        items.forEach(function (_item) {
          vm.RelationNews.push(_item)
        })
      })
    },
    // 搜尋延伸新聞的新聞列表
    searchExtendingNewsList (_keyword) {
      let vm = this
      this.processSearchNewsList(_keyword, function (items) {
        vm.ExtendingNews = []
        items.forEach(function (_item) {
          vm.ExtendingNews.push(_item)
        })
      })
    },
    // 快速搜尋新聞清單
    processSearchNewsList (_keyword, _callback) {
      this.$root.apis.getFastNewsList({
        name: _keyword
      }, function (_response) {
        _callback(_response.body.data.items)
      })
    },
    // 增加一列新的文字導購項目
    addNewGuide () {
      const new_one = {
        text: '',
        uri: ''
      }
      if (this.currentGuideList.length < 7) {
        this.currentGuideList.push(new_one)
      }
    },
    // 移除一列文字導購項目
    deleteGuide (_index) {
      this.currentGuideList.splice(_index, 1)
    },
    // 增加一個次級分類設定
    addNewSecond () {
      this.secondNewsTypeList.push({
        main: { id: "" },
        sub: { id: "" }
      })
    },
    // 移除一個次級分類設定
    deleteSecond (_index) {
      this.secondNewsTypeList.splice(_index, 1)
    },
    // 獲得公告內容
    getArticleBoardDetail () {
      let vm = this
      this.$root.apis.getArticleBoardDetail(1, function (_response) {
        vm.ArticleBoard = _response.body.data
      })
    },
    // 建立一篇文章
    createArticle () {
      // 必要欄位檢查
      if (!this.checkArticleField()) {
        return
      }

      // 獲得整理過後的文章資料
      let article = this.processArticleData()

      // 請求後端建立文章
      this.$root.apis.createNewArticle(article, this.createArticleSuccess, this.createArticleError)
    },
    // 更新一篇文章
    putArticleDetail () {
      if (this.processStatus) {
        return
      }

      // 必要欄位檢查
      if (!this.checkArticleField()) {
        return
      }

      // 獲得整理過後的文章資料
      let article = this.processArticleData()

      // 請求後端建立文章
      this.processStatus = true
      this.$root.apis.putArticleDetail(this.articleId, article, this.putArticleSuccess, this.putArticleError)
    },
    // 檢查必要填寫項目
    checkArticleField () {
      // 檢查文章分類
      if (!this.currentMainNewsType || !this.currentSubNewsType) {
        this.$root.common.showErrorDialog("文章主要分類未選擇")
        return false
      }

      // 檢查文章標題
      if (!this.currentNewsTitle || !this.currentShareTitle) {
        this.$root.common.showErrorDialog("文章標題或社群標題尚未填寫")
        return false
      }

      // 檢查文章本文
      if (!this.articleContent) {
        this.$root.common.showErrorDialog("文章內文尚未填寫")
        return false
      }

      // 檢查文章大圖 - 20231107移除圖片必填檢查
      // if (!this.currentNewsImage || !this.currentNewsShareImage) {
      //   this.$root.common.showErrorDialog("文章圖片未選擇")
      //   return false
      // }

      // 檢查標籤 - 20231107移除標籤必填檢查
      // if (!this.currentNewsTags || this.currentNewsTags.length === 0) {
      //   this.$root.common.showErrorDialog("文章標籤未選擇")
      //   return false
      // }

      return true
    },
    // 整理文章資料以後送
    processArticleData () {
      // 進行資料整理
      let ArticleSubTypes = []
      if (this.secondNewsTypeList.length > 0) {
        this.secondNewsTypeList.forEach(function (_type) {
          ArticleSubTypes.push({
            mid: _type.main.id,
            sid: _type.sub.id
          })
        })
      }

      let ArticleTags = []
      let SetNewTags = []
      if (this.currentNewsTags && this.currentNewsTags.length > 0) {
        this.currentNewsTags.forEach(function (_tag) {
          // 處理需要附加的 Tags
          if (_tag.id) {
            ArticleTags.push(_tag.id)
          }
          // 處理需要增加的 Tags
          else {
            SetNewTags.push(_tag.name)
          }
        })
      }
      // 處理相關報導
      let Relation
      if (this.currentRelationType) {
        Relation = {
          use_type: this.currentRelationType.id,
          tag: (this.currentRelationTags) ? this.currentRelationTags.id : "",
          codes: []
        }
        if (this.currentRelationNews) {
          this.currentRelationNews.forEach(function (_item) {
            Relation.codes.push(_item.code)
          })
        }
      }
      // 處理延伸新聞
      let Extending
      if (this.currentExtendingType) {
        Extending = {
          use_type: this.currentExtendingType.id,
          tag: (this.currentExtendingTags) ? this.currentExtendingTags.id : "",
          codes: []
        }
        if (this.currentExtendingNews) {
          this.currentExtendingNews.forEach(function (_item) {
            Extending.codes.push(_item.code)
          })
        }
      }
      // 整理文章資料
      let article = {
        main_type: {
          mid: this.currentMainNewsType?.id || '',
          sid: this.currentSubNewsType?.id || ''
        },
        sub_types: ArticleSubTypes,
        wancoin: this.currentWanCoin,
        use_niname: (this.isEditorUseNiName === 'accepted') ? true : false,
        editer_name: this.currentEditerName,
        post_date: this.$root.common.getNowTime('date', this.currentPostDate),
        post_time: this.$root.common.getNowTime('time', this.currentPostDate),
        post_platform: this.currentReleasePlatform,
        no_unpost: (this.isArticleNoOffline === 'accepted') ? true : false,
        unpost_date: this.$root.common.getNowTime('date', this.currentDownDate),
        unpost_time: this.$root.common.getNowTime('time', this.currentDownDate),
        news_class: this.currentNewsType,
        marks: this.currentNewsMarks,
        image: this.currentNewsImage?.url || '',
        title: this.currentNewsTitle,
        description: this.currentNewsDes,
        og_image: this.currentNewsShareImage?.url || '',
        og_title: this.currentShareTitle,
        og_description: this.currentShareDes,
        tags: ArticleTags,
        set_tags: SetNewTags,
        video_id: (this.currentNewsVideo) ? this.currentNewsVideo.url : "",
        content: this.articleContent,
        open_voice: (this.isOpenArticleVoice === 'accepted') ? true : false,
        send_broadcast: (this.currentNeedBroadcast) ? true : false,
        relation_news: Relation,
        extending_news: Extending,
        directs: this.currentGuideList
      }
      console.log("=> article:", article)

      return article
    },
    // 建立新文章成功
    async createArticleSuccess (_response) {
      // 獲得新的文章 ID
      let ArticleId = await _response.body.id
      // 更新文章ID
      this.articleId = await ArticleId
      // 重新獲得文章資料
      await this.getNewsDetail()
      // 跳出通知
      await this.$root.common.showSingleNotify("文章建立成功")
      // 前往文章編輯頁
      await this.$router.push({ path: "/article/editor/" + ArticleId })
    },
    // 建立新文章時發生問題
    createArticleError (_error) {
      let error = _error.body.data
      this.$root.common.showErrorDialog(error)
    },
    // 更新文章成功
    putArticleSuccess (_response) {
      this.getNewsDetail()
      this.processStatus = false
      this.$root.common.showSingleNotify("文章更新成功")
    },
    // 更新文章失敗
    putArticleError (_error) {
      let error = _error.body.data
      this.processStatus = false
      this.$root.common.showErrorDialog(error)
    },
    // 請求送審一篇文章
    submitArticle () {
      let vm = this
      this.$root.common.confirmAction(
        "稿件送審操作",
        "您確定要將此稿件進行 送審 操作嗎？",
        "送審", "先不要",
        function () {
          vm.$root.apis.submitArticle(vm.articleId, function () {
            vm.$root.common.showSingleNotify("稿件送審成功！")
            vm.getNewsDetail()
          })
        }
      )
    },
    // 請求退回一篇文章
    returnArticle () {
      let vm = this
      this.$root.common.confirmAction(
        "稿件退回操作",
        "您確定要將此稿件進行 退回 操作嗎？",
        "退回", "先不要",
        function () {
          vm.$root.apis.returnArticle(vm.articleId, function () {
            vm.$root.common.showSingleNotify("稿件退回成功！")
            vm.getNewsDetail()
          })
        }
      )
    },
    // 請求上架一篇文章
    setArticleOnline () {
      let vm = this
      this.$root.common.confirmAction(
        "稿件上架操作",
        "您確定要將此稿件進行 上架 操作嗎？",
        "上架", "先不要",
        function () {
          vm.$root.apis.setArticleOnline(vm.articleId, function () {
            vm.$root.common.showSingleNotify("稿件上架成功！")
            vm.getNewsDetail()
          })
        }
      )
    },
    // 請求下架一篇文章
    setArticleOffline () {
      let vm = this
      this.$root.common.confirmAction(
        "稿件下架操作",
        "您確定要將此稿件進行 下架 操作嗎？",
        "下架", "先不要",
        function () {
          vm.$root.apis.setArticleOffline(vm.articleId, function () {
            vm.$root.common.showSingleNotify("稿件下架成功！")
            vm.getNewsDetail()
          })
        }
      )
    },
    // 前往稿件列表
    async goArticleList () {
      this.$router.push({ name: 'ArticleList', params: {} })
    },
    // 檢查是否有編輯過
    checkIsEdit () {
      let article = this.processArticleData()
      return JSON.stringify(this.initialArticle) !== JSON.stringify(article)
    },
    // 主分類改變時清除子分類
    onMainTypeChange (_event, _target, _resetIndex = 0) {
      if (_target === 'currentMainNewsType') {
        this.currentMainNewsType = _event
        this.currentSubNewsType = null
      } else {
        this.secondNewsTypeList.splice(_resetIndex, 1, {
          main: _event,
          sub: { id: " " }
        })
      }
    },
    // 請求編輯文章
    async editArticle (_articleId) {
      // 如果是新建立的文章，就不處理告警
      if (this.articleId === 'create')
        return

      // 驗證是否可以編輯
      let vm = this
      await this.$root.apis.checkArticleCanEditStatus(_articleId,
        async function (_response) {
          let response = _response.body.data
          // 稿件可以編輯
          if (response.can_edit) {
            return
          }
          // 稿件不能編輯
          else {
            // 預設無主管權限時
            let accessText = "晚點再試"
            let doNotAnything = async function () {
              await vm.$router.push({ name: 'ArticleList' })
            }
            let accessAction = async function () {
              await vm.$router.push({ name: 'ArticleList' })
            }
            // 如果有主管權限時
            if (vm.$root.common.confirmUserAccess('ARTICLE_PUBLISH_CONTENT')) {
              accessText = "強制編輯(僅限主管)"
              accessAction = async function () {
                await vm.$root.apis.resetArticleCanEditStatus(_articleId, true)
                await vm.$root.apis.checkArticleCanEditStatus(_articleId)
              }
            }

            vm.$root.common.confirmAction(
              "稿件編輯中",
              "目前系統記錄顯示稿件正由用戶『" + response.edit_manager + "』編輯",
              accessText,
              "放棄編輯",
              accessAction,
              doNotAnything
            )
          }
        },
        function (_error) {
          vm.$root.common.showErrorNotify(_error.body.data)
        })
    },
    async processAutoSaveSeconds () {
      if (!this.autoSaveSecondStatus) {
        console.log('--> 自動快取狀態未啟用');
        return
      }

      // 沒有標題或內容，就不處理自動快取
      if (!this.currentNewsTitle || !this.articleContent) {
        console.log('--> 稿件未設定標題或內容');
        return
      }

      // 如果正在處理快取中，就不繼續作業
      if (this.autoSaveSecondLock) {
        console.log('--> 稿件快取作業中');
        return
      }

      let articleData = await this.processArticleCacheData()
      if (md5(JSON.stringify(this.autoSaveTempData)) === md5(JSON.stringify(articleData))) {
        console.log('--> 快取資料與目前資料相同');
        return
      }

      if (this.autoSaveSeconds <= 0) {
        this.autoSaveSeconds = 60
        return
      }

      this.autoSaveSeconds -= 1
    },
    processArticleCacheData () {
      return {
        currentArticleSource: this.currentArticleSource,
        editor: this.editor,
        articleContent: this.articleContent,
        currentNewsType: this.currentNewsType,
        currentMainNewsType: this.currentMainNewsType,
        currentSubNewsType: this.currentSubNewsType,
        currentWanCoin: this.currentWanCoin,
        currentEditer: this.currentEditer,
        currentEditerName: this.currentEditerName,
        currentReleasePlatform: this.currentReleasePlatform,
        currentNeedBroadcast: this.currentNeedBroadcast,
        currentIsNoMark: this.currentIsNoMark,
        currentNewsMarks: this.currentNewsMarks,
        secondNewsTypeList: this.secondNewsTypeList,
        currentArticleCode: this.currentArticleCode,
        currentPostDate: this.currentPostDate,
        currentDownDate: this.currentDownDate,
        currentNewsImage: this.currentNewsImage,
        currentNewsShareImage: this.currentNewsShareImage,
        currentNewsTitle: this.currentNewsTitle,
        currentShareTitle: this.currentShareTitle,
        currentNewsDes: this.currentNewsDes,
        currentShareDes: this.currentShareDes,
        currentNewsVideo: this.currentNewsVideo,
        show_Video: this.show_Video,
        currentNewsTags: this.currentNewsTags,
        currentRelationTags: this.currentRelationTags,
        currentRelationNews: this.currentRelationNews,
        currentExtendingTags: this.currentExtendingTags,
        currentExtendingNews: this.currentExtendingNews,
        NewsTags: this.NewsTags,
        RelationTags: this.RelationTags,
        RelationNews: this.RelationNews,
        ExtendingTags: this.ExtendingTags,
        ExtendingNews: this.ExtendingNews,
        isEditorUseNiName: this.isEditorUseNiName,
        isArticleNoOffline: this.isArticleNoOffline,
        isOpenArticleVoice: this.isOpenArticleVoice,
        OtherArticleTypes: this.OtherArticleTypes,
        currentRelationType: this.currentRelationType,
        currentExtendingType: this.currentExtendingType,
        currentHasPublicVersion: this.currentHasPublicVersion,
        currentArticleStatus: this.currentArticleStatus,
        currentGuideList: this.currentGuideList,
        ArticleBoard: this.ArticleBoard,
      }
    },
    setArticleCache () {
      // 獲得整理過後的文章資料
      let article = this.processArticleCacheData()
      article.cacheTime = this.$root.common.getNowTime("timestamp")
      // 開始處理稿件暫存操作
      this.autoSaveSecondLock = true
      this.$root.apis.setArticleCache(this.articleId, article, this.setArticleCacheSuccess, this.articleCacheError)
    },
    setArticleCacheSuccess (_response) {
      this.autoSaveSecondLock = false
    },
    getArticleCache (_lastEditVersion = 0) {
      let vm = this
      this.autoSaveSecondStatus = false
      this.$root.apis.getArticleCache(this.articleId, function (_response) {

        let editTime = _lastEditVersion
        let article = _response.body.data
        vm.cacheArticleContent = article

        if (article.length !== 0) {
          console.log('--> 最後編輯時間:', editTime)
          console.log('--> 上次快取時間:', article.cacheTime)

          vm.isNewCacheVersion = article.cacheTime > editTime
          // if (article.cacheTime > editTime) {
          //   // vm.$root.common.confirmAction("新的快取版本", "檢測到較新的快取紀錄<br>請問要套用較新的快取資料嗎？", "替換", "不要")
          // }
        }

        // 開啟自動快取狀態
        vm.autoSaveSecondStatus = true

        // 處理文章編輯準備狀態
        setTimeout(() => {
          vm.articleReadyStatus = true
        }, 1000);

      }, this.articleCacheError)
    },
    useCacheArticleContent () {
      if (this.cacheArticleContent.length !== 0) {
        let vm = this
        let article = this.cacheArticleContent

        // 處理文章資料轉移
        vm.currentArticleSource = article.currentArticleSource
        vm.editor = article.editor
        vm.articleContent = article.articleContent
        vm.currentNewsType = article.currentNewsType
        vm.currentMainNewsType = article.currentMainNewsType
        vm.currentSubNewsType = article.currentSubNewsType
        vm.currentWanCoin = article.currentWanCoin
        vm.currentEditer = article.currentEditer
        vm.currentEditerName = article.currentEditerName
        vm.currentReleasePlatform = article.currentReleasePlatform
        vm.currentNeedBroadcast = article.currentNeedBroadcast
        vm.currentIsNoMark = article.currentIsNoMark
        vm.currentNewsMarks = article.currentNewsMarks
        vm.secondNewsTypeList = article.secondNewsTypeList
        vm.currentArticleCode = article.currentArticleCode
        vm.currentPostDate = article.currentPostDate
        vm.currentDownDate = article.currentDownDate
        vm.currentNewsImage = article.currentNewsImage
        vm.currentNewsShareImage = article.currentNewsShareImage
        vm.currentNewsTitle = article.currentNewsTitle
        vm.currentShareTitle = article.currentShareTitle
        vm.currentNewsDes = article.currentNewsDes
        vm.currentShareDes = article.currentShareDes
        vm.currentNewsVideo = article.currentNewsVideo
        vm.show_Video = article.show_Video
        vm.currentNewsTags = article.currentNewsTags
        vm.currentRelationTags = article.currentRelationTags
        vm.currentRelationNews = article.currentRelationNews
        vm.currentExtendingTags = article.currentExtendingTags
        vm.currentExtendingNews = article.currentExtendingNews
        vm.NewsTags = article.NewsTags
        vm.RelationTags = article.RelationTags
        vm.RelationNews = article.RelationNews
        vm.ExtendingTags = article.ExtendingTags
        vm.ExtendingNews = article.ExtendingNews
        vm.isEditorUseNiName = article.isEditorUseNiName
        vm.isArticleNoOffline = article.isArticleNoOffline
        vm.isOpenArticleVoice = article.isOpenArticleVoice
        vm.OtherArticleTypes = article.OtherArticleTypes
        vm.currentRelationType = article.currentRelationType
        vm.currentExtendingType = article.currentExtendingType
        vm.currentHasPublicVersion = article.currentHasPublicVersion
        vm.currentArticleStatus = article.currentArticleStatus
        vm.currentGuideList = article.currentGuideList
        vm.ArticleBoard = article.ArticleBoard
        // 開啟自動快取狀態
        vm.autoSaveSecondStatus = true
        // 處理暫存的資料檢查
        vm.autoSaveTempData = vm.processArticleCacheData()
      }
    },
    articleCacheError () {
      this.autoSaveSecondLock = false
    },
    showAutoSaveInfo () {
      this.$root.common.showSuccessDialog(
        "關於自動快取",
        "頁面會在每60秒自動執行備份，系統雖有備份，仍請記得在每次編輯或離開時，點選「暫存」避免遺失內容。" +
        "<br><br>" +
        "特別說明: 下次進入同一稿件，系統偵測到有最新的備份，將詢問是否需要以系統備份檔案開啟。",
        "了解", ""
      )
    },
    needShowEditorName (_currentEditerName = "", _ownEditorName = "") {
      if (_currentEditerName === _ownEditorName) {
        return false
      }

      if (["即時中心", "要聞中心", "娛樂中心", "大國中心", "健康中心"].indexOf(_currentEditerName) > -1) {
        return false
      }

      return true
    },
    searchArticleList (_keyword) {
      let vm = this
      this.processSearchNewsList(_keyword, function (items) {
        vm.searchArticles = []
        items.forEach(function (_item) {
          vm.searchArticles.push(_item)
        })
      })
    },
    setNewsContent (_data) {
      console.log('--> 更新新聞稿件內容:', _data)
      this.articleContent = _data
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  }
};
</script>

<style lang="sass" scoped>
.title-box
  display: flex
  justify-content: space-between
  align-items: center
  .addbtn
    display: flex
    justify-content: center
    align-items: center
    .ri-file-add-fill
      font-size: 1.4rem
      line-height: 1.5rem
.card-header
  background: #fff
.label-select
  border: none
  padding: 0
  height: auto
  line-height: 1
  font-weight: 600
.header-row
  display: flex
  align-items: center
  .header-title
    margin: 0
    flex: 1

.tab-group
  background: rgba(229,231,235,1)
  padding: 0.5rem 1rem
  button.off
    color: rgba(156,163,175,1)
    background: rgba(0,0,0,0)
    border-color: rgba(0,0,0,0)

.form-label-row
  display: flex
  gap: 14px

.add-btn
  width: 100%
  border: dashed 1px
.del-btn
  flex: 1
  display: flex
  align-items: center
  position: relative
  top: 3px
  .btn
    padding: 0rem .3rem
    border-radius: 50px

.announce-item
  background: #f1f5f9
  border-radius: 10px
  padding: 10px 10px 20px
  .announce-title
    font-size: 16px
    margin-bottom: 10px
  .announce-text
    padding-left: 10px
    font-size: 14px
    color: #ccc
.img-view
  width: 400px
  height: 300px
  background: #e2e8f0
  border-radius: 5px
  margin-top: 5px
  border: 1px solid #ccc
  display: flex
  align-items: center
  justify-content: center
  position: relative
  img
    width: 100%
    height: 100%
    object-fit: cover
  i
    font-size: 30px
.video-view,video.article-video
  width: auto
  height: auto
  max-width: 100%
  max-height: 100%
.gray-box
  background: #f1f5f9
  width: 100%
  padding: 10px
  display: flex
  justify-content: center

::v-deep .select2-container--default
  display: block
  width: 100%
  height: calc(1.5em + 0.9rem + 2px)
  // padding: 0.45rem 0.9rem
  font-size: 0.9rem
  font-weight: 400
  line-height: 1.5
  color: #6c757d
  background-color: #fff
  background-clip: padding-box
  border: 1px solid #ced4da
  border-radius: 0.2rem
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
  outline: none

::v-deep .select2-selection--single
  border: none
  display: flex
  align-items: center
  height: 100%
.auto-save-window
  position: fixed
  right: 0px
  bottom: 0px
  padding: 7px 30px
  background: #FFF
  border: 1px #000 solid
  z-index: 999
.auto-save-window-writing
  background: #F00  
  color: #FFF
.lock-position
  position: relative
.process-ing-icon
  position: absolute
  right: -20px
  top: -8px
  width: 1rem
  height: 1rem
a.suggestion-title
  color: #000
.auto-save-content
  cursor: default
  padding-left: 15px
.function-action
  border: 0px
  background: none
  padding: 0px 3px
  color: #0099FF
  font-weight: bold
.position-fix
  min-height: 15px
  position: relative
.text-to-voice-checkbox
  position: absolute
  right: 15px
.article-editor-container
  position: relative
  .article-editor-loading
    width: 100%
    margin: 0px auto
    text-align: center
    padding-top: 200px
    padding-bottom: 200px
</style>
